import React from 'react'
import styles from "./donations.module.css"
import ImageCarousel from '../../UI/container/newsrow/image-carousel/ImageCarousel'

import coin5 from "../../images/coin5.png"
import coin10 from "../../images/coin10.png"
import coin20 from "../../images/coin20.png"
import coin25 from "../../images/coin25.png"
import coin50 from "../../images/coin50.png"
import coin100 from "../../images/coin100.png"

function Donations() {
    return (
        <div className={styles.content_column}>
            <ImageCarousel />

            <h2 className={styles.title}>Rebirth Donations</h2>
            <div className={styles.disclaimer}>
                <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
                    Q: Why should I donate?
                </h5>

                <p className={styles.overlock_Font}>
                    Donations help us cover the server fees so that the game
                    can keep running for as long as possible. They also allow
                    us to recruit skilled developers that can help us improve the game.
                </p>

                <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
                    Q: Do I get anything by donating?
                </h5>

                <p className={styles.overlock_Font}>
                    On top of our eternal gratitude, you'll get Lucent Points which
                    can be used on the Item Mall to buy all sorts of items and costumes.
                    In addition, you'll also get Donation Gift Packs based on how much
                    you've donated thus far.
                </p>

                <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
                    Q: What are the Donation Gift Packs?
                </h5>

                <p className={styles.overlock_Font}>
                    Donation Gift Packs are a set of items that we send out to players
                    when they reach a specific donation milestone to thank them for their
                    support. The current milestones are as follows:
                    <li>Basic Donation Gift Pack - 5€</li>
                    <li>Intermediate Donation Gift Pack - 20€</li>
                    <li>Advanced Donation Gift Pack - 50€</li>
                    <li>Superior Donation Git Pack - 100€</li>
                    <li>Premium Donation Git Pack - 250€</li>
                    <li>Royal Donation Git Pack - 500€</li>
                    <li>Legendary Donation Git Pack - 1000€</li>
                </p>

                <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
                    Q: What items does each Donation Gift Pack give?
                </h5>

                <p className={styles.overlock_Font}>
                    That is a secret for now!
                </p>

                <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
                    Q: Where and when do I get the Donation Gift Packs?
                </h5>

                <p className={styles.overlock_Font}>
                    At the moment we still haven't completed creating all Donation Gift Packs, so you will not receive any for now.
                    However, once all Donation Gift Packs are created, you will receive each one of them via Item Mall whenever you reach their designated donation milsestone.
                </p>

                <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
                    Q: How can I donate?
                </h5>

                <p className={styles.overlock_Font}>
                    We currently take donations via <u><b>Stripe</b></u> and <u><b>Paypal</b></u>. Scroll down to see the donation options.
                </p>

                <h5 className={`${styles.overlock_Font} ${styles.bottom_line}`}>
                    Q: How long do I need to wait to receive my Lucent Points?
                </h5>

                <p className={styles.overlock_Font}>
                    Since at the moment we have no way to automatically send Lucent Points to
                    your account, you might need to wait a while before you receive them. Once
                    your donation has been processed it can take from a <u>couple of minutes</u> to a <u>couple of hours</u> for your LP to be sent.
                    We apologize in advance for this matter
                    and we're very grateful for your understanding. We're looking into a way of
                    making the donation process more efficient for everyone.
                </p>

                <p className={styles.overlock_Font}>
                    Feel free to send a <b>ticket</b> on <u>Discord</u> to talk about your donation and rewards status if it has been more than 24 hours.
                </p>
                <br></br>
                <h4><span>Disclaimer</span></h4>
                <p className={styles.overlock_Font}>
                By donating to Lucent Heart Rebirth, you confirm your adherence to our <a href="/rules">Rules of Conduct</a> and <a href="/support">Support Policies</a>,
                which include but are not limited to the fact that donations are non-reversible and cannot be refunded for any reason, and that you will not request any refunds or initiate chargebacks.
                You also understand that donations might take some time to process, and therefore, it will take some time for your Lucent Points to be added to your account.
                </p>

            
                <p className={styles.overlock_Font2}>
                <b>Important:</b> Do not forget to properly type your <u>login ID</u> on the donation checkout so that we are able to send the Lucent Points to your account.
                </p>

                <p className={styles.overlock_Font2}>Please be patient. Donations take time to process.
                Do not spam the staff members about your donation.
                <b>Wait at least <u>24 hours</u> before sending a ticket regarding your donation status.</b></p>
            </div>


            <div className={styles.paymentOptions}>
                <div className={styles.coinDiv}>
                    <img src={coin5} className={styles.coinImage} />
                    <h3 className={styles.coinSum}>5.000 LP</h3>
                    <a target="_blank" href="https://donate.stripe.com/14keWo0cxbTL9BS7st">
                        <button className={`${styles.payButton} ${styles.stripeButton}`}> Donate 5€ with Stripe </button>
                    </a>
                    <a target="_blank" href="https://www.paypal.com/donate/?hosted_button_id=GWDJTMZY3P9N8">
                        <button className={`${styles.payButton} ${styles.paypalButton}`}>Donate 5€ with PayPal</button>
                    </a>
                </div>

                <div className={styles.coinDiv}>
                    <img src={coin10} className={styles.coinImage} />
                    <h3 className={styles.coinSum}>12.000 LP</h3>
                    <a target="_blank" href="https://donate.stripe.com/cN2cOge3nf5X3du144">
                        <button className={`${styles.payButton} ${styles.stripeButton}`}> Donate 10€ with Stripe </button>
                    </a>
                    <a target="_blank" href="https://www.paypal.com/donate/?hosted_button_id=NH2E5F6SRN4W2">
                        <button className={`${styles.payButton} ${styles.paypalButton}`}>Donate 10€ with PayPal</button>
                    </a>
                </div>

                <div className={styles.coinDiv}>
                    <img src={coin20} className={styles.coinImage} />
                    <h3 className={styles.coinSum}>25.000 LP</h3>
                    <a target="_blank" href="https://donate.stripe.com/6oEg0s9N76zr8xO4gi">
                        <button className={`${styles.payButton} ${styles.stripeButton}`}> Donate 20€ with Stripe </button>
                    </a>
                    <a target="_blank" href="https://www.paypal.com/donate/?hosted_button_id=NDGNGUAY3H46N">
                        <button className={`${styles.payButton} ${styles.paypalButton}`}>Donate 20€ with PayPal</button>
                    </a>
                </div>

                <div className={styles.coinDiv}>
                    <img src={coin25} className={styles.coinImage} />
                    <h3 className={styles.coinSum}>33.000 LP</h3>
                    <a target="_blank" href="https://donate.stripe.com/00geWof7rga129q4gj">
                        <button className={`${styles.payButton} ${styles.stripeButton}`}> Donate 25€ with Stripe </button>
                    </a>
                    <a target="_blank" href="https://www.paypal.com/donate/?hosted_button_id=PRW9GW4FN6GRS">
                        <button className={`${styles.payButton} ${styles.paypalButton}`}>Donate 25€ with PayPal</button>
                    </a>
                </div>

                <div className={styles.coinDiv}>
                    <img src={coin50} className={styles.coinImage} />
                    <h3 className={styles.coinSum}>68.000 LP</h3>
                    <a target="_blank" href="https://donate.stripe.com/28oaG8aRbe1T7tK004">
                        <button className={`${styles.payButton} ${styles.stripeButton}`}> Donate 50€ with Stripe </button>
                    </a>
                    <a target="_blank" href="https://www.paypal.com/donate/?hosted_button_id=6PCXAAHP95FVW">
                        <button className={`${styles.payButton} ${styles.paypalButton}`}>Donate 50€ with PayPal</button>
                    </a>
                </div>

                <div className={styles.coinDiv}>
                    <img src={coin100} className={styles.coinImage} />
                    <h3 className={styles.coinSum}>150.000 LP</h3>
                    <a target="_blank" href="https://donate.stripe.com/bIY5lO5wR5vn3du8wB">
                        <button className={`${styles.payButton} ${styles.stripeButton}`}> Donate 100€ with Stripe </button>
                    </a>
                    <a target="_blank" href="https://www.paypal.com/donate/?hosted_button_id=U5YZNAKQNZMAQ">
                        <button className={`${styles.payButton} ${styles.paypalButton}`}>Donate 100€ with PayPal</button>
                    </a>
                </div>
            </div>


        </div>

    )
}

export default Donations